import React, { useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  Button,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
  Badge,
} from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import { filterByField } from "./services/api";

export default function DashboardCampaigns() {
  const navigate = useNavigate();
  const params = useParams();

  const [registerDataDashboard, setRegisterDataDashboard] = useState([]);
  const [subscriptionDataDashboard, setSubscriptionDataDashboard] = useState(
    []
  );
  const [donationDataDashboard, setDonationDataDashboard] = useState([]);

  async function loadRegisters() {
    const registers = await filterByField("registers");

    return registers;
  }

  async function loadSubscriptions() {
    const subscriptions = await filterByField(
      "subscriptions",
      "channelId",
      params.channelId
    );

    return subscriptions;
  }

  async function loadDonations() {
    const donations = await filterByField(
      "donations",
      "channelId",
      params.channelId
    );

    return donations;
  }

  async function registerData() {
    const registers = await loadRegisters();

    const registerData = [
      {
        header: "Registers Info",
        body: {
          title: "Total Register",
          value: registers.length,
          description: "Total accounts registered",
        },
      },
    ];

    setRegisterDataDashboard(registerData);
  }

  async function subscriptionData() {
    const subscriptions = await loadSubscriptions();
    const existsCodes = [];
    subscriptions.forEach((subscription) => {
      if (!existsCodes.includes(subscription.code)) {
        existsCodes.push(subscription.code);
      }
    });

    const uniqueSubscriptions = [];
    subscriptions.forEach((subscription) => {
      if (
        uniqueSubscriptions.find((item) => {
          return item.uniqueSubscriptions === subscription.registerId;
        })
      ) {
        uniqueSubscriptions.find((item) => {
          return item.uniqueSubscriptions === subscription.registerId;
        });
      } else {
        uniqueSubscriptions.push({
          uniqueSubscriptions: subscription.registerId,
          code: subscription.code,
        });
      }
    });

    const registerId = [];
    subscriptions.forEach((subscription) => {
      if (!registerId.includes(subscription.registerId)) {
        registerId.push(subscription.registerId);
      }
    });

    const allCodes = uniqueSubscriptions.map((subscription) => {
      return subscription.code;
    });

    let codeCount = [];
    existsCodes.forEach((code) => {
      const count = allCodes.filter((element) => element === code);
      const obj = {
        key: count[0],
        value: count.length,
        description: "",
      };
      if (code === "BR23SAVE") {
        obj.description = "Save the date";
      }
      if (code === "BR23READ") {
        obj.description = "Leitura finalizada";
      }
      if (code === "BRD23NTL") {
        obj.description = "Doação única";
      }
      if (code === "BRDR23NTL") {
        obj.description = "Doação recorrente";
      }
      if (code === "br23natal") {
        obj.description = "Notícias e atualizações do ministério";
      }

      codeCount.push(obj);
    });

    console.log(codeCount.description);

    const subscriptionData = [
      {
        header: "Subscription Info",
        body: codeCount,
      },
    ];

    setSubscriptionDataDashboard(subscriptionData);
  }

  async function donationData() {
    const donations = await loadDonations();
    const totalDonationValue = sumCalculator([donations]);

    const detailDonationCompleted = donationTypeTotal([donations]);

    const totalDonationPerCode = donationPerCode([donations]);

    const numberOfDonationCard = numberOfDonationsData([donations]);

    totalDonationPerCode.forEach((code) => {
      if (code.key === "BRD23NTL") {
        detailDonationCompleted.push({
          key: "One time",
          value: `R$ ${code.value}`,
          description: code.key,
        });
      } else {
        detailDonationCompleted.push({
          key: "Recurrent",
          value: `R$ ${code.value}`,
          description: code.key,
        });
      }
    });

    const donationData = [
      {
        header: "Donations Info",
        body: [
          {
            title: "Completed Donation Total",
            total: `R$ ${totalDonationValue.completed}`,
            description: "Completed Donation",
            details: detailDonationCompleted,
          },
          {
            title: "Number of donations",
            total: numberOfDonationCard.uniqueDonations,
            details: [
              ...numberOfDonationCard.totalPerStatus,
              {
                key: "Average Donation",
                value: `R$ ${numberOfDonationCard.averageDonation}`,
              },
            ],
          },
        ],
      },
    ];

    setDonationDataDashboard(donationData);
  }

  function numberOfDonationsData([donations]) {
    const completedDonations = donations.filter(
      (donation) => donation.donationStatus === "Completed"
    );

    const registerIDDonations = [];
    const uniqueDonations = [];
    completedDonations.forEach((donation) => {
      if (!registerIDDonations.includes(donation.registerId)) {
        registerIDDonations.push(donation.registerId);
        uniqueDonations.push(donation);
      }
    });

    const donationStatus = [];
    donations.forEach((donation) => {
      if (
        donationStatus.find((item) => {
          return item.donationStatus === donation.donationStatus;
        })
      ) {
        donationStatus.find((item) => {
          return item.donationStatus === donation.donationStatus;
        }).value++;
      } else {
        donationStatus.push({
          donationStatus: donation.donationStatus,
          value: 1,
        });
      }
    });

    const newDonationStatus = donationStatus.map((element) => {
      if (element.donationStatus !== "TEST") {
        if (element.donationStatus === "pending") {
          element.donationStatus = "Created";
        }
        return {
          key: element.donationStatus,
          value: element.value,
        };
      }
      return "";
    });

    const filteredDonationStatus = newDonationStatus.filter(
      (item) => item !== ""
    );

    const donationTotalValue = sumCalculator([completedDonations]);

    let averageDonation = donationTotalValue.completed / uniqueDonations.length;

    averageDonation = Math.trunc(averageDonation);

    return {
      uniqueDonations: uniqueDonations.length,
      averageDonation: averageDonation,
      totalPerStatus: filteredDonationStatus,
    };
  }

  function sumCalculator([value]) {
    let totalCompleted = 0;

    value.forEach((element) => {
      if (element.donationStatus === "Completed") {
        let currentNumber = parseInt(element.value);
        totalCompleted = currentNumber + totalCompleted;
      }
    });
    return {
      completed: totalCompleted,
    };
  }

  function donationTypeTotal([donations]) {
    const donationTypes = [];
    donations.forEach((element) => {
      if (
        !donationTypes.includes(element.donationType) &&
        element.donationType
      ) {
        donationTypes.push(element.donationType);
      }
      return;
    });
    // FILTER ONLY THE TYPES
    const filteredDonation = donations.filter(
      (element) => element.donationType
    );

    // EXCLUDES TYPES WITH UNDEFINED VALUES
    const donationTypesTotal = donationTypes.map((types) => {
      const uniqueType = filteredDonation.filter(
        (obj) => obj.donationType === types
      );

      let total = sumCalculator([uniqueType]);

      return {
        key: types,
        value: `R$ ${total.completed}`,
      };
    });

    return donationTypesTotal;
  }

  function donationPerCode([donations]) {
    const codes = [];
    donations.forEach((element) => {
      if (!codes.includes(element.motivationCode)) {
        codes.push(element.motivationCode);
      }
      return;
    });

    const donationsPerCodes = codes.map((item) => {
      const filteredDonations = donations.filter(
        (element) => element.motivationCode === item
      );

      const totalDonation = sumCalculator([filteredDonations]);

      return {
        key: item,
        value: totalDonation.completed,
      };
    });

    return donationsPerCodes;
  }

  const renderTooltip = (props) => <Tooltip>{props}</Tooltip>;

  useEffect(() => {
    registerData();
    subscriptionData();
    donationData();
  }, []);
  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        className="mt-4 d-flex"
        variant="secondary"
      >
        Back
      </Button>
      <Card id="registers-card" className="mt-3">
        {registerDataDashboard.map((item, title) => (
          <React.Fragment key={title}>
            <Button
              onClick={() => navigate("/tools/registers")}
              className="fw-bold fs-3"
              variant="info"
            >
              Registers
            </Button>
            <Row className="container m-3">
              <Col className="row row-cols-auto grid gap-3">
                <Card className="dashboard-card p-0">
                  <Card.Header className="card-title">
                    {item.body.title}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title className="card-value">
                      {item.body.value}
                    </Card.Title>
                    <Card.Text className="border-top card-description">
                      {item.body.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        ))}
      </Card>
      <Card id="subscriptions-card" className="mt-3">
        {subscriptionDataDashboard.map((element, title) => (
          <React.Fragment key={title}>
            <Button
              onClick={() => navigate("/tools/subscriptions")}
              className="fw-bold fs-3"
              variant="info"
            >
              Subscriptions
            </Button>
            <Row className="container m-3">
              <Col className="row row-cols-auto grid gap-3">
                {element.body.map((item, key) => (
                  <React.Fragment key={key}>
                    <Card className="dashboard-card p-0">
                      <Card.Header className="card-title">
                        {item.key}
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card-value">
                          {item.value}
                        </Card.Title>
                        <Card.Text className="border-top card-description">
                          {item.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </React.Fragment>
                ))}
              </Col>
            </Row>
          </React.Fragment>
        ))}
      </Card>
      <Card id="donations-card" className="mt-3 text-center">
        {donationDataDashboard.map((item, title) => (
          <React.Fragment key={title}>
            <Button
              onClick={() => navigate("/tools/donations")}
              className="fw-bold fs-3"
              variant="info"
            >
              Donations
            </Button>
            <Row className="container m-3">
              <Col className="row row-cols-auto grid gap-3">
                {item.body.map((element, title) => (
                  <React.Fragment key={title}>
                    <Card className="dashboard-card p-0">
                      <Card.Header className="fw-bold">
                        {element.title}
                      </Card.Header>
                      <Card.Body>
                        <Card.Title className="card-value">
                          {element.total}
                        </Card.Title>
                        {element.details &&
                          element.details.map((element, title) => (
                            <React.Fragment key={title}>
                              <ListGroup className="border-top align-middle list-group-flush">
                                <ListGroup.Item className="d-flex justify-content-between">
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip(element.description)}
                                  >
                                    <Badge
                                      bg={
                                        element.key === "canceled"
                                          ? "danger"
                                          : element.key === "Completed"
                                          ? "success"
                                          : (element.key === "Created") |
                                            (element.key ===
                                              "PAYMENT_METHOD_SELECTED")
                                          ? "warning"
                                          : "primary"
                                      }
                                    >
                                      {element.key}
                                    </Badge>
                                  </OverlayTrigger>{" "}
                                  <span>{"-"}</span>
                                  <span>{element.value}</span>
                                </ListGroup.Item>
                              </ListGroup>
                            </React.Fragment>
                          ))}
                        <Card.Text className="border-top card-description">
                          {element.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </React.Fragment>
                ))}
              </Col>
            </Row>
          </React.Fragment>
        ))}
      </Card>
    </>
  );
}
