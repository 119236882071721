import React, { useEffect, useState } from "react";
import { format, getMonth, subMonths, getYear } from "date-fns";
import { apiUniversitario as ApiPaoDiario } from "../../../services/api";
import { Dropdown, Spinner, Row, Col, Card, ListGroup } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChanelCard from "../../../components/AudienceChannelCard";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);

  function dropdownItem() {
    const arrItems = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const thisDate = subMonths(currentDate, i);
      arrItems.push(
        <Dropdown.Item
          onClick={() => {
            setCurrentDate(thisDate);
          }}
        >
          Reference month: {format(thisDate, "MMMM / Y")}
        </Dropdown.Item>
      );
    }
    return arrItems;
  }

  useEffect(() => {
    async function fetchMonthData() {
      setLoading(true);
      try {
        const response = await ApiPaoDiario.get(
          `dashboard/usage?month=${getMonth(currentDate) + 1}&year=${getYear(
            currentDate
          )}`
        );

        setReportData(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchMonthData();
  }, [currentDate]);

  function DataCard(title, value, description = "", type = "number") {
    const labels = [];

    const data = {
      labels,
      datasets: [
        {
          label: "Qty Usage",
          data: [],
          backgroundColor: "rgba(248, 179, 48, 0.5)",
        },
      ],
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "",
        },
      },
    };
    if (type === "chart" && value) {
      value.forEach((item) => {
        labels.push(item.hour);
        data.datasets[0].data.push(item.qty);
      });
    }

    return (
      <Card className="dashboard-card">
        <Card.Header className="card-title">{title}</Card.Header>
        <Card.Body>
          {type === "number" && (
            <Card.Title className="card-value">{value}</Card.Title>
          )}
          {type === "table" && (
            <ListGroup className="list-group-flush">
              {value?.map((item, index) => {
                return (
                  <ListGroup.Item>{`${index + 1} - ${item.name} - ${
                    item.qty
                  }`}</ListGroup.Item>
                );
              })}
            </ListGroup>
          )}
          {type === "chart" && <Bar options={options} data={data} />}

          <Card.Text className="card-description">{description}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
  return (
    <>
      <Row className="p-3">
        <h1 className="my-3">Usage report: APP Pão Diário - Universitário</h1>
        <Dropdown className="mb-3">
          <Dropdown.Toggle id="dropdown-basic">
            Reference month: {format(currentDate, "MMMM / Y")}
          </Dropdown.Toggle>

          <Dropdown.Menu>{dropdownItem()}</Dropdown.Menu>
        </Dropdown>
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!loading && reportData && (
          <>
            <Row>
              <Col>
                {DataCard(
                  "Devotional reads",
                  reportData?.data?.monthly_engagement?.qty_devotional,
                  "Qty times devotional was readed"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Verses reads",
                  reportData?.data?.monthly_engagement?.qty_verses,
                  "People readed Bible in a Year verses"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Devotional Shared",
                  reportData?.data?.monthly_engagement?.qty_share,
                  "Qty devotional was shared"
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {DataCard(
                  "Used by unregistered people",
                  reportData?.data?.monthly_engagement?.qty_unregistered,
                  "People used the content but not registed on app"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Total usage by everyone",
                  reportData?.data?.monthly_engagement?.qty_usage,
                  "Total devices used content, registered and unregistered"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Reminders setted",
                  reportData?.data?.summary?.qty_reminders,
                  "Total devices with reminder setted"
                )}
              </Col>
            </Row>
            <Row>
              <h2>Engagement</h2>
              <Col>
                {DataCard(
                  "Top 10 Devotional",
                  reportData?.data?.monthly_engagement?.top_10?.devotional,
                  "",
                  "table"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Top 10 Verses",
                  reportData?.data?.monthly_engagement?.top_10?.verses,
                  "",
                  "table"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Top 10 Shares",
                  reportData?.data?.monthly_engagement?.top_10?.share,
                  "",
                  "table"
                )}
              </Col>
            </Row>
            <Row>
              <h2 className="mt-3">Time</h2>
              <Col>
                {DataCard(
                  "Hourly Usage",
                  reportData?.data?.monthly_engagement?.hourly_usage,
                  "",
                  "chart"
                )}
              </Col>
            </Row>
            <Row>
              <h2 className="mt-3">Registration</h2>
              <Col>
                <ChanelCard
                  api={ApiPaoDiario}
                  channelTitle="Pão Diário Universitário"
                />
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
}
