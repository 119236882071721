import axios from "axios";

export const apiChannels = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: "Bearer " + process.env.REACT_APP_API_KEY,
  },
});

export const listDonations = async () => {
  try {
    const response = await apiChannels.get("donations");

    return response.data;
  } catch (error) {
    return new Error("List Donations Error: ", error.message);
  }
};

export const listChannels = async () => {
  try {
    const response = await apiChannels.get("channels");

    return response.data;
  } catch (error) {
    return new Error("List Channels Error: ", error.message);
  }
};

export const listRegisters = async () => {
  try {
    const response = await apiChannels.get("registers");

    return response.data;
  } catch (error) {
    return new Error("List Registers Error: ", error.message);
  }
};

export const filterByField = async (table, field, value) => {
  try {
    if (!field) {
      field = "";
    }
    if (!value) {
      value = "";
    }
    const response = await apiChannels.get(
      `${table}?term=${field}&value=${value}`
    );

    return response.data;
  } catch (error) {
    return new Error("Filter By Field Error: ", error.message);
  }
};

export const sendToSalesforce = async (subscriptionId) => {
  try {
    const response = await apiChannels.post(
      `subscriptions/${subscriptionId}/syncToSF`
    );

    return response;
  } catch (error) {
    throw new Error("Sending to endpoint", error);
  }
};
