//External imports
import React, { useEffect, useRef } from "react";

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

export default function GoogleAuth({ loginReponse }) {
  const googleButton = useRef(null);

  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    const src = "https://accounts.google.com/gsi/client";
    const id = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    loadScript(src)
      .then(() => {
        /*global google*/
        // console.log(google);
        google.accounts.id.initialize({
          client_id: id,
          callback: (response) => {
            const responseJwt = parseJwt(response.credential);
            // console.log("Encoded JWT ID token: " + response.credential);
            // console.log("Encoded JWT ID token: " + reponseJwt.name);
            loginReponse(responseJwt);
          },
        });
        google.accounts.id.renderButton(googleButton.current, {
          theme: "outline",
          size: "large",
        });
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, [loginReponse]);

  return <div ref={googleButton}>opa</div>;
}
