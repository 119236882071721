import React, { useEffect, useState } from "react";

import { Spinner, Card, Row, Col } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ChanelGroupedCard({ channelTitle, apis }) {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDataSets] = useState([]);
  const [datasetsNewsletter, setDataSetsNewsletter] = useState([]);

  useEffect(() => {
    async function fetchData({ api, title, color }) {
      try {
        const response = await api.get(`dashboard/registers-by-year`);
        const total = response.data.data.summary.total;
        const totalLabels = [];
        total.forEach((item) => {
          totalLabels.push(item.year);
        });

        setLabels((prevState) => {
          const newArrLabels = prevState.concat(totalLabels);
          newArrLabels.filter((item, index) => {
            return newArrLabels.indexOf(item) === index;
          });
          return newArrLabels.filter((item, index) => {
            return newArrLabels.indexOf(item) === index;
          });
        });
        return {
          summary: response.data.data.summary,
          title,
          color,
        };
        // setReportData(response.data.data);
        // setLoading(false);
      } catch (e) {
        console.log(e);
        // setLoading(false);
      }
    }
    async function updateReport() {
      setLoading(true);
      const promisses = [];
      apis.forEach((item) => {
        promisses.push(fetchData(item));
      });
      const responses = await Promise.all(promisses);
      //   console.log("responses", responses);
      setReportData(responses);
      //   setLoading(false);
    }
    updateReport();
  }, [apis]);

  useEffect(() => {
    function processQty(item, field) {
      return {
        label: item.title,
        data: labels.map((label) => {
          const currentYearTotal = item.summary[field].find(
            (summaryTotalItem) => summaryTotalItem.year === label
          );
          if (currentYearTotal) {
            return currentYearTotal.qty;
          } else {
            return 0;
          }
        }),
        stack: "Stack 0",
        backgroundColor: item.color,
      };
    }
    // console.log("labels after", labels);
    // console.log("responses after", reportData);

    const newDataSet = [];
    const newDataSetNewsletter = [];

    reportData.forEach((item) => {
      newDataSetNewsletter.push(processQty(item, "newsletter"));
      newDataSet.push(processQty(item, "total"));
    });
    console.log("newDataSet", newDataSet);
    // console.log("newDataSetNewsletter", newDataSetNewsletter);
    setDataSets(newDataSet);
    setDataSetsNewsletter(newDataSetNewsletter);
    setLoading(false);
  }, [labels, reportData]);

  function table(arrayDataset) {
    let sums = [];
    return (
      <table border={1} className="table-content">
        <thead>
          <tr>
            <th>Application</th>
            {labels.map((label) => (
              <th>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {arrayDataset.map((channel) => {
            return (
              <tr>
                <td>{channel.label}</td>
                {channel.data.map((yearData, index) => {
                  if (!sums[index]) sums[index] = 0;
                  sums[index] += parseInt(yearData);
                  return <td>{yearData}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            {sums.map((sum) => {
              return <td>{sum}</td>;
            })}
          </tr>
        </tfoot>
      </table>
    );
  }
  function Chart(title, arrayDataset) {
    const data = {
      labels,
      datasets: arrayDataset,
    };
    const options = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },

      plugins: {
        title: {
          display: true,
          text: title,
        },
      },
    };

    return <Bar options={options} data={data} />;
  }

  return (
    <Card className="dashboard-card">
      <Card.Header className="card-title">{channelTitle}</Card.Header>
      <Card.Body>
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!loading && (
          <>
            <Row>
              <Col>
                <Card className="dashboard-card">
                  <Card.Header className="card-title">Total</Card.Header>
                  <Card.Body>
                    {Chart("Total Users registered by year", datasets)}
                    {table(datasets)}
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card className="dashboard-card">
                  <Card.Header className="card-title">Newsletter</Card.Header>
                  <Card.Body>
                    {Chart(
                      "Total Users registered in newsletter by year",
                      datasetsNewsletter
                    )}
                    {table(datasetsNewsletter)}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
