import React, { useEffect, useState } from "react";
import { format } from "date-fns";

import { Spinner, Card } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ChanelCard({ channelTitle, api }) {
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({});

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const response = await api.get(`dashboard/registers`);

        setReportData(response.data.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchData();
  }, [api]);

  function Chart() {
    const labels = [];

    const data = {
      labels,
      datasets: [
        {
          label: "Qty Registration",
          data: [],
          backgroundColor: "rgba(248, 179, 48, 0.5)",
        },
      ],
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "",
        },
      },
    };

    reportData?.summary?.forEach((item) => {
      const referenceMonth = new Date();
      referenceMonth.setMonth(item.month - 1);
      referenceMonth.setFullYear(item.year);
      labels.push(format(referenceMonth, "MMMM / Y"));
      data.datasets[0].data.push(item.qty);
    });

    return <Bar options={options} data={data} />;
  }

  return (
    <Card className="dashboard-card">
      <Card.Header className="card-title">{channelTitle}</Card.Header>
      <Card.Body>
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!loading && (
          <>
            <Card.Title className="card-value">
              {reportData.total || ""}
            </Card.Title>
            <Card.Text className="card-description">
              users registered entire life
            </Card.Text>

            {Chart(reportData)}
            <Card.Text className="card-description">
              users registers per month
            </Card.Text>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
