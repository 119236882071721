import React, { useEffect } from "react";
import { Pagination, Dropdown } from "react-bootstrap";

function Paginate({ currentPage, itemsPerPage, items, pagedDataUpdated }) {
  const pageNumbers = [];

  const totalPages = Math.ceil(items.length / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  function updateList(page) {
    pagedDataUpdated({ pageNumber: page, data: refreshData(page) });
  }

  function refreshData(page) {
    return items.slice(page * itemsPerPage - itemsPerPage, page * itemsPerPage);
  }
  useEffect(() => {
    updateList(currentPage.pageNumber);
  }, [items]);

  let propsPrev = {};
  if (currentPage.pageNumber <= 1) {
    propsPrev.disabled = true;
  }

  let propsNext = {};
  if (currentPage.pageNumber === totalPages) {
    propsNext.disabled = true;
  }

  return (
    <>
      <Pagination>
        <Pagination.First
          {...propsPrev}
          onClick={() => {
            updateList(1);
          }}
        />
        <Pagination.Prev
          {...propsPrev}
          onClick={() => {
            updateList(currentPage.pageNumber - 1);
          }}
        />
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            {currentPage.pageNumber}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {pageNumbers.map((item) => {
              let itemProp = {};
              if (item === currentPage.pageNumber) {
                itemProp.active = true;
              }
              return (
                <React.Fragment key={item}>
                  <Dropdown.Item
                    {...itemProp}
                    onClick={() => {
                      updateList(item);
                    }}
                  >
                    {item}
                  </Dropdown.Item>
                </React.Fragment>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
        <Pagination.Next
          {...propsNext}
          onClick={() => {
            updateList(currentPage.pageNumber + 1);
          }}
        />
        <Pagination.Last
          {...propsNext}
          onClick={() => {
            updateList(totalPages);
          }}
        />
      </Pagination>
    </>
  );
}

export { Paginate };
