import axios from "axios";

export const apiWhatsappChannel = axios.create({
  baseURL: "https://whatsapp.paodiario.org", //"http://localhost:3333",
  headers: {
    Authorization: "Bearer " + process.env.REACT_APP_WHATSAPP_API_DB_TOKEN, //TOKEN DB
  },
});

export const createInstance = async (instance_key) => {
  try {
    if (instance_key) {
      const response = await apiWhatsappChannel.get(
        `instance/init?key=${instance_key}`
      );

      if (response.status === 200) {
        return response;
      }
    }
  } catch (error) {
    return new Error(error.message);
  }
};

export const generateQRCode = async (instance_key) => {
  try {
    if (instance_key) {
      const qrCode = await apiWhatsappChannel.get(
        `instance/qrbase64?key=${instance_key}`
      );

      return qrCode;
    }
  } catch (error) {
    return new Error(error.message);
  }
};

export const instanceInfo = async (instance_key) => {
  try {
    const response = await apiWhatsappChannel.get(
      `instance/info?key=${instance_key}`
    );

    return response;
  } catch (error) {
    return new Error(error.message);
  }
};

export const listAllSessions = async () => {
  try {
    const response = await apiWhatsappChannel.get("instance/list");

    return response;
  } catch (error) {
    return new Error(error.message);
  }
};

export const listAllInstances = async () => {
  try {
    const response = await apiWhatsappChannel.get("instance/list");
    const allInstanceKey = response.data.data.map(({ instance_key }) => [
      instance_key.toString(),
    ]);
    return allInstanceKey;
  } catch (error) {
    return new Error(error.message);
  }
};

export const logoutSession = async (instance_key) => {
  try {
    await apiWhatsappChannel.delete(`instance/logout?key=${instance_key}`);

    return true;
  } catch (error) {
    return new Error(error.message);
  }
};
export const deleteInstance = async (instance_key) => {
  try {
    await apiWhatsappChannel.delete(`instance/delete?key=${instance_key}`);
  } catch (error) {
    return new Error(error.message);
  }
};

export const deleteAllInstances = async () => {
  const sessionsToDeleted = await listAllInstances();

  if (sessionsToDeleted) {
    for (let index = 0; index < 300; index++) {
      const sessionKey = sessionsToDeleted[index];
      const key = sessionKey[0];

      await apiWhatsappChannel.delete(`instance/delete?key=${key}`);
    }
  } else {
    throw new Error("No sessions to delete");
  }
};
