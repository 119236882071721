import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import Image from "react-image-webp";

export default function Menu() {
  const [userName, setUserName] = useState("-");

  const logOut = () => {
    localStorage.setItem("GIA-UserName", "");
    window.location.reload(false);
  };
  useEffect(() => {
    setUserName(localStorage.getItem("GIA-UserName"));
  }, []);

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
            <Image
              src={require("./assets/img/logo.png")}
              webp={require("./assets/img/logo.png")}
              alt="ODB logo"
              width="32px"
            />
          </Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <NavDropdown title="Dashboards" id="navbarScrollingDropdown">
              <NavDropdown.Item href="/dashboard/app-pao-diario">
                Montlhy usage: App Pão Diário
              </NavDropdown.Item>
              <NavDropdown.Item href="/dashboard/app-pao-diario-militar">
                Montlhy usage: App Militar
              </NavDropdown.Item>
              <NavDropdown.Item href="/dashboard/app-pao-diario-universitario">
                Montlhy usage: App Universitário
              </NavDropdown.Item>
              <NavDropdown.Item href="/dashboard/audience">
                Audience
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Tools">
              <NavDropdown.Item href="/tools/whatsapp-channels">
                WhatsApp Channels
              </NavDropdown.Item>
              <NavDropdown.Item href="/tools/channels">
                Channels
              </NavDropdown.Item>
              <NavDropdown.Item href="/tools/registers">
                Registers
              </NavDropdown.Item>
              <NavDropdown.Item href="/tools/donations">
                Donations
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav className="ml-auto">
            <NavDropdown title={userName} id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}
