import * as React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Layout from "../layout/default";
import Home from "../pages/home";
import DashboardAppPaoDiario from "../pages/dashboard/app-pao-diario";
import DashboardAppPaoDiarioMilitar from "../pages/dashboard/app-pao-diario-militar";
import DashboardAppPaoDiarioUniversitario from "../pages/dashboard/app-pao-diario-universitario";
import Audience from "../pages/dashboard/audience";
import WhatsappChannels from "../pages/tools/whatsapp-channels";
import Channels from "../pages/tools/channels";
import Registers from "../pages/tools/registers";
import Donations from "../pages/tools/donations";
import Subscriptions from "../pages/tools/subscriptions";
import DashboardCampaigns from "../pages/tools/dashboardCampaigns";

const App = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <Home /> },
        {
          path: "dashboard/app-pao-diario",
          element: <DashboardAppPaoDiario />,
        },
        {
          path: "dashboard/app-pao-diario-militar",
          element: <DashboardAppPaoDiarioMilitar />,
        },
        {
          path: "dashboard/app-pao-diario-universitario",
          element: <DashboardAppPaoDiarioUniversitario />,
        },
        {
          path: "dashboard/audience",
          element: <Audience />,
        },
        {
          path: "tools/whatsapp-channels",
          element: <WhatsappChannels />,
        },
        {
          path: "tools/channels",
          element: <Channels />,
        },
        {
          path: "tools/channels/:channelId/dashboard",
          element: <DashboardCampaigns />,
        },
        {
          path: "tools/channels/:channelId/subscriptions",
          element: <Subscriptions />,
        },
        {
          path: "tools/channels/:channelId/donations",
          element: <Donations />,
        },
        {
          path: "tools/registers",
          element: <Registers />,
        },
        {
          path: "tools/registers/:registerId/subscriptions",
          element: <Subscriptions />,
        },
        {
          path: "tools/registers/:registerId/donations",
          element: <Donations />,
        },
        {
          path: "tools/donations",
          element: <Donations />,
        },
        {
          path: "tools/subscriptions",
          element: <Subscriptions />,
        },
      ],
    },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
