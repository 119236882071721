import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card, Table, Badge, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  listRegisters,
  listSubscriptions,
  listDonations,
} from "./services/api";
import { Paginate } from "../components/Paginate";
import { format } from "date-fns";

export default function Registers() {
  const navigate = useNavigate();

  const [registers, setRegisters] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({ pageNumber: 1, data: [] });
  const itemsPerPage = 10;

  async function loadSubscription() {
    const subscriptions = await listSubscriptions();

    return subscriptions;
  }

  async function loadDonations() {
    const donations = await listDonations();

    return donations;
  }

  async function loadRegisters() {
    const data = await listRegisters();

    data.sort((a, b) => {
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.created_at < b.created_at) {
        return 1;
      }

      return 0;
    });

    return data;
  }

  async function renderDataScreen() {
    setIsLoading(true);
    const registers = await loadRegisters();
    const subscriptions = await loadSubscription();
    const donations = await loadDonations();
    const newRegister = registers.map((element) => {
      const filteredDonations = donations.filter(
        (item) => item.registerId === element.registerId
      );
      const filteredSubscriptions = subscriptions.filter(
        (item) => item.registerId === element.registerId
      );

      return {
        ...element,
        subscriptions: filteredSubscriptions.length,
        donations: filteredDonations.length,
      };
    });

    const dashboardData = [
      {
        title: "Total Register",
        value: registers.length,
        description: "Total accounts registered",
      },
    ];

    setDashboardData(dashboardData);

    setRegisters(newRegister);

    setIsLoading(false);
  }

  function handleClickedDonation(item) {
    navigate(`${item.registerId}/donations`);
    console.log(item);
  }

  function handleClickedSubscription(item) {
    navigate(`${item.registerId}/subscriptions`);
    console.log(item);
  }

  useEffect(() => {
    loadSubscription();
    renderDataScreen();
  }, []);
  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        className="mt-4 d-flex"
        variant="secondary"
      >
        Back
      </Button>

      <Row className="container mt-3">
        <Col className="row row-cols-auto grid gap-3">
          {dashboardData.map((item, title) => (
            <React.Fragment key={title}>
              <Card className="dashboard-card">
                <Card.Header className="card-title">{item.title}</Card.Header>
                <Card.Body>
                  <Card.Title className="card-value">{item.value}</Card.Title>
                  <Card.Text className="border-top card-description">
                    {item.description}
                  </Card.Text>
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Col>
      </Row>

      <Card className="mt-4 text-center">
        <Card.Body>
          <Card.Title>
            <Paginate
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              items={registers}
              pagedDataUpdated={(newData) => {
                setCurrentPage(newData);
              }}
            />

            <span bg="primary" className="fw-bold">
              Registers
            </span>
          </Card.Title>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subscriptions</th>
                  <th>Donations</th>
                  <th>Date</th>
                </tr>
              </thead>
              {currentPage.data.map((item, registerId) => (
                <React.Fragment key={item.registerId}>
                  <tbody>
                    <tr>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>
                        {" "}
                        <Button
                          variant="outline-primary"
                          onClick={() => handleClickedSubscription(item)}
                        >
                          Subscriptions{" "}
                          <Badge bg="secondary">{item.subscriptions}</Badge>
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="outline-primary"
                          onClick={() => handleClickedDonation(item)}
                        >
                          Donations{" "}
                          <Badge bg="secondary">{item.donations}</Badge>
                        </Button>
                      </td>
                      <td>
                        {format(new Date(item.created_at), "HH:mm - d/M/Y")}
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              ))}
            </Table>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
