import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Card, Badge, Button } from "react-bootstrap";
import { listChannels, filterByField } from "./services/api";

export default function Channels() {
  const navigate = useNavigate();

  const [channels, setChannels] = useState([]);

  // async function loadDataBagdes(channelId) {
  //   const filteredSubscriptions = await filterByField(
  //     "subscriptions",
  //     "channelId",
  //     channelId
  //   );
  //   const registerIDSubscription = [];
  //   if (filteredSubscriptions && filteredSubscriptions.data) {
  //     filteredSubscriptions.data.forEach((subscription) => {
  //       if (!registerIDSubscription.includes(subscription.registerId)) {
  //         registerIDSubscription.push(subscription.registerId);
  //       }
  //     });
  //   }

  //   document.getElementById(`subs-${channelId}`).innerHTML =
  //     registerIDSubscription.length;

  //   const filteredDonations = await filterByField(
  //     "donations",
  //     "channelId",
  //     channelId
  //   );
  //   if (filteredDonations && filteredDonations.data) {
  //     const completedDonations = filteredDonations.data.filter(
  //       (item) => item.donationStatus === "Completed"
  //     );
  //   }

  //   // completedDonations.length;
  // }

  async function getChannelsList() {
    const data = await listChannels();

    setChannels(data.data);
  }

  function clickedSubscriptionsButton(channel) {
    navigate(`${channel.channelId}/subscriptions`);
    console.log(channel);
  }

  function clickedDonationsButton(channel) {
    navigate(`${channel.channelId}/donations`);
    console.log(channel);
  }

  function clickedDashboardButton(channel) {
    navigate(`${channel.channelId}/dashboard`);
    console.log(channel);
  }

  useEffect(() => {
    getChannelsList();
  }, []);

  return (
    <Card className="mt-4 text-center">
      <Card.Body>
        <Card.Title className="fw-bold"> Channels </Card.Title>
        <Table bordered hover size="sm" responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>URL</th>
              <th>Country</th>
              <th>Motivation Code</th>
              <th>Subscriptions</th>
              <th>Donations</th>
              <th>Dashboard</th>
            </tr>
          </thead>
          {channels.map((item) => {
            return (
              <React.Fragment key={item.channelId}>
                <tbody>
                  <tr>
                    <td>{item.name}</td>
                    <td>
                      <Card.Link href={item.url}>{item.url}</Card.Link>
                    </td>
                    <td>{item.country}</td>
                    <td>{item.codes.join(", ")}</td>
                    <td>
                      <Button
                        variant="outline-primary"
                        onClick={() => clickedSubscriptionsButton(item)}
                      >
                        Subs{" "}
                        <Badge bg="secondary" id={`subs-${item.channelId}`}>
                          {item.qtySub || "-"}
                        </Badge>
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        onClick={() => clickedDonationsButton(item)}
                      >
                        Donations{" "}
                        <Badge
                          bg="secondary"
                          id={`donations-${item.channelId}`}
                        >
                          {item.qtyDonation || "-"}
                        </Badge>
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="outline-primary"
                        onClick={() => clickedDashboardButton(item)}
                      >
                        Dashboard
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </React.Fragment>
            );
          })}
        </Table>
      </Card.Body>
    </Card>
  );
}
