/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  createInstance,
  deleteInstance,
  generateQRCode,
  instanceInfo,
  logoutSession,
} from "./services/api";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/esm/Spinner";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { HeaderGridContent } from "./components/HeaderGrid";

export function Separator() {
  return <div className="vr border border-2"></div>;
}

export function NumberFormatter(value) {
  const countryPrefix = value.slice(0, 2);
  const ddd = value.slice(2, 4);
  const phoneNumber = value.slice(4, 8);
  const phoneNumber2 = value.slice(8, 12);

  const numberFormatted = `+${countryPrefix} ${ddd} ${phoneNumber}-${phoneNumber2}`;

  return numberFormatted;
}

export default function WhatsappChannels() {
  const channelsWhatsapp = [
    {
      channelID: "PAODIARIO_MSR",
      description: "Whatsapp Oficial do Pão Diário",
      number: null,
      name: " ",
    },
    {
      channelID: "PAODIARIO_SALES",
      description: "Whatsapp Oficial do Pão Diário Vendas",
      number: null,
      name: " ",
    },
  ];

  const [isLoadingQr, setIsLoadingQr] = useState(true);
  const [qrCode, setQrCode] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [shouldRefresh, setShouldRefresh] = useState(0);
  const [channelList, setChannelList] = useState([]);

  const [currentChannelId, setCurrentChannelId] = useState("");

  async function RefreshList() {
    try {
      const updatedFunctions = channelsWhatsapp.map(async function (channel) {
        let instance = await instanceInfo(channel.channelID);

        if (instance.status !== 200) {
          await createInstance(channel.channelID);
          instance = await instanceInfo(channel.channelID);
        }

        if (instance.data.instance_data.user.id) {
          const phoneNumber = NumberFormatter(
            instance.data.instance_data.user.id
          );

          const name = instance?.data?.instance_data?.user?.name;
          return { ...channel, number: phoneNumber, name: name };
        }

        return { ...channel, number: null };
      });
      const updatedSessions = await Promise.all(updatedFunctions);

      setChannelList((prevState, props) => {
        return updatedSessions;
      });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async function RenderQrCode(channelId) {
    const response = await generateQRCode(channelId);

    if (response?.data?.qrcode !== " ") {
      console.log("|" + response?.data?.qrcode + "|");
      setQrCode(response?.data?.qrcode);
      setIsLoadingQr(false);

      if (isModalOpen) {
        const user = await UserConnected(channelId);
        const allUsers = Object.keys(user).length;

        if (allUsers !== 0) {
          userHasConnected();
        } else {
          await new Promise((resolve) => setTimeout(resolve, 3000));

          setShouldRefresh(shouldRefresh + 1);
        }
      }
    } else {
      await createInstance(channelId);
      await RenderQrCode(channelId);
    }
  }

  async function UserConnected(channelID) {
    const response = await instanceInfo(channelID);
    const instance = response.data.instance_data;

    return instance.user;
  }

  async function handleClickOnConnectOrDisconnect(channelId, isConnect) {
    if (isConnect) {
      const response = await logoutSession(channelId);

      await deleteInstance(channelId);
      if (response) {
        RefreshList();
      }
    } else {
      setIsModalOpen(true);
      setCurrentChannelId(channelId);
    }
  }

  function modalClose() {
    setIsModalOpen(false);
    setShouldRefresh(false);
  }

  function userHasConnected() {
    setShouldRefresh(false);
    setIsModalOpen(false);

    RefreshList();
  }

  useEffect(() => {
    RefreshList();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      RenderQrCode(currentChannelId);
    }
  }, [isModalOpen, shouldRefresh]);

  return (
    <div className="mt-4">
      <h1 className="p-2">Whatsapp Channels</h1>
      <div className="d-inline justify-content-start">
        {/* HEADER GRID */}
        <HeaderGridContent />
        {/* HEADER GRID */}
        {/* BODY GRID */}
        {channelList.map((item, channelID) => (
          <React.Fragment key={item.channelID}>
            <ListGroup className="text-center border rounded-1" horizontal>
              <Separator />
              <ListGroup.Item className="col p-3">
                {item?.channelID}
              </ListGroup.Item>
              <Separator />
              <ListGroup.Item className="col p-2">
                {item?.description}
              </ListGroup.Item>
              <Separator />
              <ListGroup.Item className="col p-3">
                {item?.number}
              </ListGroup.Item>
              <Separator />
              <ListGroup.Item className="col p-3">{item?.name}</ListGroup.Item>
              <Separator />
              <ListGroup.Item className="col p-3">
                <Button
                  variant={item.number ? "outline-danger" : "outline-success"}
                  size="sm"
                  onClick={() => {
                    handleClickOnConnectOrDisconnect(
                      item.channelID,
                      item.number ? true : false
                    );
                  }}
                >
                  {item.number ? "Disconnect" : "Connect"}
                </Button>
              </ListGroup.Item>
              <Separator />
            </ListGroup>
          </React.Fragment>
        ))}
        {/* BODY GRID */}
        {/* MODAL */}
        {isModalOpen && (
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            onHide={modalClose}
            show
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Connect to {currentChannelId}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center">
              <div>
                {isLoadingQr ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  <Container>
                    <Row className="justify-content-center">
                      <Col>
                        <Image src={qrCode} thumbnail />
                      </Col>
                    </Row>
                  </Container>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={modalClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {/* MODAL */}
      </div>
    </div>
  );
}
