import "./App.scss";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "react-bootstrap";
import GoogleAuth from "./components/GoogleAuth";
import Router from "./routes";
import Card from "react-bootstrap/Card";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const onSuccess = (res) => {
    // console.log("success:", res);
    localStorage.setItem("GIA-UserName", res.name);
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const username = localStorage.getItem("GIA-UserName");
    // console.log("logged", username);
    if (username) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <ThemeProvider className="App">
      {!isAuthenticated && (
        <div className="login-content">
          <Card style={{ width: "18rem" }}>
            <Card.Header>Login with your ODB account</Card.Header>

            <Card.Body>
              <GoogleAuth loginReponse={(response) => onSuccess(response)} />
            </Card.Body>
          </Card>
        </div>
      )}
      {isAuthenticated && <Router />}
    </ThemeProvider>
  );
}

export default App;
