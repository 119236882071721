import React, { useEffect, useState } from "react";
import { format, getMonth, subMonths, getYear } from "date-fns";
import ApiPaoDiario from "../../../services/api";
import { Dropdown, Spinner, Row, Col, Card, ListGroup } from "react-bootstrap";
import ModalTable from "../../../components/ModalTable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChanelCard from "../../../components/AudienceChannelCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function Dashboard() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const CMS_BASE_URL =
    "https://publicacoespaodiario.com.br/clube/clube-api/public_html/cms/";
  const pageNames = [
    { slug: "HOME", title: "Home" },
    { slug: "BIBLE", title: "Bible" },
    { slug: "LOGIN", title: "Login Page" },
    { slug: "NEWS_VIEW", title: "News detail", url: "news/edit/id/:id" },
    { slug: "RADIO", title: "Radio" },
    { slug: "SHOP", title: "Store" },
    { slug: "SUBSCRIPTION_MODAL", title: "Subscription modal opened" },
    {
      slug: "PLAN_OPENED",
      title: "Reading Plan Opened",
      url: "plans/edit/id/:id/",
    },
    { slug: "REGISTER", title: "Register" },
    {
      slug: "PLAN_FINISHED",
      title: "Reading Plan Finished",
      url: "plans/edit/id/:id/",
    },
    { slug: "VIDEO_OPENED", title: "Video detail", url: "videos/edit/id/:id/" },
    {
      slug: "ARTICLES_VIEW",
      title: "Article detail",
      url: "news-articles/edit/id/:id/",
    },
    {
      slug: "PODCAST_PLAY",
      title: "Podcast Play",
      url: "podcasts/edit/id/:id/",
    },
    {
      slug: "PODCAST_VIEW",
      title: "Podcast View",
      url: "podcasts/edit/id/:id/",
    },
    {
      slug: "PROJECT_OPENED",
      title: "Project Opened",
      url: "projects/edit/id/:id/",
    },
    { slug: "VIDEO_VIEW", title: "Video Watched", url: "videos/edit/id/:id/" },
  ];

  function getPageName(slug) {
    let title = slug;

    for (let i = 0; i < pageNames.length; i++) {
      if (pageNames[i].slug === slug) {
        title = pageNames[i].title;
      }
    }
    return title;
  }
  function getPageUrl(slug) {
    let url = slug;

    for (let i = 0; i < pageNames.length; i++) {
      if (pageNames[i].slug === slug) {
        url = pageNames[i].url || false;
      }
    }
    return url;
  }
  function dropdownItem() {
    const arrItems = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const thisDate = subMonths(currentDate, i);
      arrItems.push(
        <Dropdown.Item
          onClick={() => {
            setCurrentDate(thisDate);
          }}
        >
          Reference month: {format(thisDate, "MMMM / Y")}
        </Dropdown.Item>
      );
    }
    return arrItems;
  }

  useEffect(() => {
    async function fetchMonthData() {
      setLoading(true);
      try {
        const response = await ApiPaoDiario.get(
          `dashboard/usage?month=${getMonth(currentDate) + 1}&year=${getYear(
            currentDate
          )}`
        );

        setReportData(response.data);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
    fetchMonthData();
  }, [currentDate]);

  function DataCard(title, value, description = "", type = "number") {
    const labels = [];

    const data = {
      labels,
      datasets: [
        {
          label: "Qty Usage",
          data: [],
          backgroundColor: "rgba(248, 179, 48, 0.5)",
        },
      ],
    };
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "bottom",
        },
        title: {
          display: false,
          text: "",
        },
      },
    };
    if (type === "chart" && value) {
      value.forEach((item) => {
        labels.push(item.hour);
        data.datasets[0].data.push(item.qty);
      });
    }

    return (
      <Card className="dashboard-card">
        <Card.Header className="card-title">{title}</Card.Header>
        <Card.Body>
          {type === "number" && (
            <Card.Title className="card-value">{value}</Card.Title>
          )}
          {type === "table-ranking" && (
            <ListGroup className="list-group-flush">
              {value?.map((item, index) => {
                return (
                  <ListGroup.Item>{`${index + 1} - ${item.name} - ${
                    item.qty
                  }`}</ListGroup.Item>
                );
              })}
            </ListGroup>
          )}
          {type === "table-summary" && (
            <ListGroup className="list-group-flush table-summary">
              {value?.map((item, index) => {
                return (
                  <ListGroup.Item className="summary_item">
                    {getPageUrl(item.slug) ? (
                      <ModalTable
                        modalTitle={getPageName(item.slug)}
                        label={getPageName(item.slug)}
                        api={ApiPaoDiario}
                        url={`dashboard/summary-by-slug?slug=${
                          item.slug
                        }&month=${getMonth(currentDate) + 1}&year=${getYear(
                          currentDate
                        )}`}
                        cmsUrl={`${CMS_BASE_URL}${getPageUrl(item.slug)}`}
                      />
                    ) : (
                      <label className="label_summary">{`${getPageName(
                        item.slug
                      )}`}</label>
                    )}
                    <span className="qty_summary">{`${item.qty}`}</span>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          )}
          {type === "chart" && <Bar options={options} data={data} />}

          <Card.Text className="card-description">{description}</Card.Text>
        </Card.Body>
      </Card>
    );
  }
  return (
    <>
      <Row className="p-3">
        <h1 className="my-3">Usage report: APP Pão Diário</h1>
        <Dropdown className="mb-3">
          <Dropdown.Toggle id="dropdown-basic">
            Reference month: {format(currentDate, "MMMM / Y")}
          </Dropdown.Toggle>

          <Dropdown.Menu>{dropdownItem()}</Dropdown.Menu>
        </Dropdown>
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        {!loading && reportData && (
          <>
            <Row>
              <Col>
                {DataCard(
                  "Devotional reads",
                  reportData?.data?.monthly_engagement?.qty_devotional,
                  "Qty times devotional was readed"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Verses reads",
                  reportData?.data?.monthly_engagement?.qty_verses,
                  "People readed Bible in a Year verses"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Devotional Shared",
                  reportData?.data?.monthly_engagement?.qty_share,
                  "Qty devotional was shared"
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                {DataCard(
                  "Used by unregistered people",
                  reportData?.data?.monthly_engagement?.qty_unregistered,
                  "People used the content but not registed on app"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Total usage by everyone",
                  reportData?.data?.monthly_engagement?.qty_usage,
                  "Total devices used content, registered and unregistered"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Reminders setted",
                  reportData?.data?.summary?.qty_reminders,
                  "Total devices with reminder setted"
                )}
              </Col>
            </Row>
            <Row>
              <h2>Engagement</h2>
              <Col>
                {DataCard(
                  "Top 10 Devotional",
                  reportData?.data?.monthly_engagement?.top_10?.devotional,
                  "",
                  "table-ranking"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Top 10 Verses",
                  reportData?.data?.monthly_engagement?.top_10?.verses,
                  "",
                  "table-ranking"
                )}
              </Col>
              <Col>
                {DataCard(
                  "Top 10 Shares",
                  reportData?.data?.monthly_engagement?.top_10?.share,
                  "",
                  "table-ranking"
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="mt-3">Time</h2>
                {DataCard(
                  "Hourly Usage",
                  reportData?.data?.monthly_engagement?.hourly_usage,
                  "",
                  "chart"
                )}
              </Col>
              <Col>
                <h2 className="mt-3">Page views</h2>
                {DataCard(
                  "Page views",
                  reportData?.data?.analytics?.summary,
                  "",
                  "table-summary"
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className="mt-3">Registration</h2>
                <ChanelCard api={ApiPaoDiario} channelTitle="Pão Diário" />
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
}
