import ListGroup from "react-bootstrap/ListGroup";

export const HeaderGridContent = () => {
  return (
    <div>
      <ListGroup className="fw-bold text-center border-4 rounded-0" horizontal>
        <ListGroup.Item
          active
          variant="dark"
          className="col text-light rounded-0 rounded-top"
        >
          Channel ID
        </ListGroup.Item>
        <ListGroup.Item active variant="dark" className="col text-light">
          Description
        </ListGroup.Item>
        <ListGroup.Item active variant="dark" className="col text-light">
          Number
        </ListGroup.Item>
        <ListGroup.Item active variant="dark" className="col text-light">
          Name
        </ListGroup.Item>
        <ListGroup.Item
          active
          variant="dark"
          className="col text-light rounded-top"
        >
          Action
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};
