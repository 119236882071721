import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_PAODIARIO,
});
export const apiMilitar = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_PAODIARIO_MILITAR,
});
export const apiUniversitario = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_PAODIARIO_UNIVERSITARIO,
});

export default api;
