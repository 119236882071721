import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Button,
  Row,
  Col,
  ListGroup,
  Spinner,
  Modal,
} from "react-bootstrap";
import { Paginate } from "../components/Paginate";
import { filterByField } from "../channels/services/api";
import {
  sendToSalesforce,
  sendAllToSalesforce,
} from "../subscriptions/services/api";
import { useParams, useNavigate } from "react-router-dom";
import { format, set } from "date-fns";

export default function Subscriptions() {
  const params = useParams();
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState([]);
  const [channel, setChannel] = useState({});
  const [countedCode, setCountedCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState("");
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    textContent: "a",
  });
  const [currentPage, setCurrentPage] = useState({ pageNumber: 1, data: [] });
  const itemsPerPage = 10;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function loadChannel() {
    const filteredChannels = await filterByField(
      "channels",
      "channelId",
      params.channelId
    );

    setChannel(filteredChannels.data[0]);
  }

  async function loadRegister() {
    const filteredRegisters = await filterByField("registers");

    return filteredRegisters.data;
  }

  async function loadSubscriptions() {
    if (params.registerId) {
      const filteredSubscriptions = await filterByField(
        "subscriptions",
        "registerId",
        params.registerId
      );

      return filteredSubscriptions.data;
    } else {
      const filteredSubscriptions = await filterByField(
        "subscriptions",
        "channelId",
        params.channelId
      );

      return filteredSubscriptions.data;
    }
  }

  async function loadData() {
    setIsLoading(true);
    const listSubscriptions = await loadSubscriptions();
    const listRegisters = await loadRegister();

    const subscriptionsWithRegister = listSubscriptions.map((item) => {
      return {
        ...item,
        register: listRegisters.find(
          (reg) => reg.registerId === item.registerId
        ),
      };
    });

    const sortedItems = subscriptionsWithRegister.map((element) => {
      return element;
    });

    sortedItems.sort((a, b) => {
      if (a.created_at > b.created_at) {
        return -1;
      }
      if (a.create_at < b.create_at) {
        return 1;
      }

      return 0;
    });

    setSubscriptions(sortedItems);
    setIsLoading(false);
  }

  async function loadDashboardData() {
    const listSubscriptions = await loadSubscriptions();

    const existsCodes = [];
    listSubscriptions.forEach((subscription) => {
      if (!existsCodes.includes(subscription.code)) {
        existsCodes.push(subscription.code);
      }
    });

    const allCodes = listSubscriptions.map((subscription) => {
      return subscription.code;
    });

    let codeCount = [];
    existsCodes.forEach((code) => {
      const count = allCodes.filter((element) => element === code);
      const obj = {
        key: [count[0]],
        value: count.length,
      };
      codeCount.push(obj);
    });
    setCountedCode(codeCount);
  }

  async function isSyncedToSalesforce() {
    subscriptions.forEach(async (subscription) => {
      subscription?.history.forEach(async (history) => {
        if (history?.listStatus[0]?.message === "Subscription Successful") {
          document
            .getElementById(`${subscription.subscriptionId}`)
            .setAttribute("disabled", "true");
          document.getElementById(`${subscription.subscriptionId}`).innerHTML =
            "Synced";
          document
            .getElementById(`${subscription.subscriptionId}`)
            .classList.add("btn-success");
          document
            .getElementById(`${subscription.subscriptionId}`)
            .classList.remove("btn-primary");
          document
            .getElementById(`${subscription.subscriptionId}`)
            .setAttribute("title", history);
        } else {
          return;
        }
      });
    });
  }

  async function syncToSalesforce(item) {
    setIsSending(item.subscriptionId);
    console.log("syncToSalesforce item", item);

    const response = await sendToSalesforce(item.subscriptionId);
    if (response.data.isSuccess) {
      document
        .getElementById(`${item.subscriptionId}`)
        .setAttribute("disabled", "true");
      document.getElementById(`${item.subscriptionId}`).innerHTML = "Synced";
      document
        .getElementById(`${item.subscriptionId}`)
        .classList.add("btn-success");
      document
        .getElementById(`${item.subscriptionId}`)
        .classList.remove("btn-primary");
      document
        .getElementById(`${item.subscriptionId}`)
        .setAttribute("title", item.history[0]);
      alert("Synced to Salesforce", "success");
    } else {
      console.log("response", response.data);
      alert(JSON.stringify(response.data.listStatus[0].message), "error");
    }
  }
  useEffect(() => {
    loadData();
    loadDashboardData();
    loadChannel();
  }, []);

  useEffect(() => {
    isSyncedToSalesforce();
  }, [currentPage]);

  return (
    <>
      <Button
        onClick={() => navigate(-1)}
        className="mt-4 d-flex"
        variant="secondary"
      >
        Back
      </Button>

      <Row className="container mt-3">
        <Col className="row row-cols-auto grid gap-3">
          {countedCode.map((item, key) => (
            <React.Fragment key={key}>
              <Card className="dashboard-card">
                <Card.Header className="card-title">{item.key}</Card.Header>
                <Card.Body>
                  <Card.Title className="card-value">{item.value}</Card.Title>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>
                      Subscription per Motivation Code
                    </ListGroup.Item>
                  </ListGroup>
                  <Card.Text className="card-description">{}</Card.Text>
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Col>
      </Row>

      <Card className="flex mt-4 text-center">
        <Card.Body className="flex">
          <Card.Title>
            <div>
              Subscription from <span className="fw-bold">{channel.name}</span>
            </div>
          </Card.Title>
          <div className="d-flex justify-content-end pb-2 px-4"></div>
          {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Consent</th>
                  <th>Newsletter</th>
                  <th>Motivation Code</th>
                  <th>Date</th>
                  <th>Sync to Salesforce</th>
                  <th>Log</th>
                </tr>
              </thead>
              {currentPage.data.map((item, subscriptionId) => (
                <React.Fragment key={item.subscriptionId}>
                  <tbody>
                    <tr>
                      <td>{item.register.name}</td>
                      <td>{item.register.email}</td>
                      <td>{item.consent}</td>
                      <td>{item.newsletter}</td>
                      <td>{item.code}</td>
                      <td>
                        {format(new Date(item.created_at), "HH:mm - d/M/Y")}
                      </td>
                      <td>
                        <Button
                          className="btnSync"
                          style={{ pointerEvents: "auto" }}
                          id={item.subscriptionId}
                          onClick={() => {
                            console.log("item", item);
                            syncToSalesforce(item);
                          }}
                        >
                          {isSending === item.subscriptionId ? "..." : "Sync"}
                        </Button>
                      </td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => {
                            setModalContent({
                              title: `subscriptionInfo: ${item.subscriptionId}`,
                              textContent: JSON.stringify(item.history),
                            });
                            handleShow();
                          }}
                        >
                          Details
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              ))}
            </Table>
          )}
          <div className="d-flex justify-content-end">
            <Paginate
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              items={subscriptions}
              pagedDataUpdated={(newData) => {
                setCurrentPage(newData);
              }}
              onClick={() => {
                isSyncedToSalesforce();
              }}
            />
          </div>
        </Card.Body>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{modalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <textarea className="w-100">{modalContent.textContent}</textarea>
          </Modal.Body>
        </Modal>
      </Card>
    </>
  );
}
