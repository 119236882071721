import React, { useState, useEffect } from "react";
import { Button, Modal, Spinner, ListGroup } from "react-bootstrap";

export default function ModalTable({ api, url, modalTitle, label, cmsUrl }) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    async function getTableData() {
      const response = await api.get(url);
      console.log(response.data.data.summary);
      if (response.data?.data?.summary) {
        setData(response.data.data.summary);
      } else {
        setData([]);
      }
    }

    getTableData();
  }, [api, url, show]);

  function generateTabel(data) {
    return (
      <ListGroup className="list-group-flush">
        {data?.map((item, index) => {
          return (
            <ListGroup.Item
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a
                href={`${cmsUrl.replace(":id", item.value)}`}
                rel="noreferrer"
                target="_blank"
                style={{ display: "flex" }}
              >
                {`View item ${item.value}`}
              </a>
              <span style={{ display: "flex" }}>{`Total:${item.qty}`}</span>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }

  return (
    <>
      <Button variant="link" className="label_summary" onClick={handleShow}>
        {label || "Click to view"}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle || "See data"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data === null ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : data?.length === 0 ? (
            <span>No data</span>
          ) : (
            generateTabel(data)
          )}
          {}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
