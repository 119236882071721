import React from "react";

import {
  api as ApiPaoDiario,
  apiMilitar as ApiMilitar,
  apiUniversitario as ApiUniversitario,
} from "../../../services/api";
import ChanelCard from "../../../components/AudienceChannelCard";
import ChanelGroupedCard from "../../../components/AudienceGroupedCard";

import { Row, Col } from "react-bootstrap";
export default function Dashboard() {
  return (
    <>
      <Row className="p-3">
        <h1 className="my-3">Audience stats</h1>

        <Row>
          <Col>
            <ChanelCard api={ApiPaoDiario} channelTitle="Pão Diário" />
          </Col>
          <Col>
            <ChanelCard api={ApiMilitar} channelTitle="Pão Diário Militar" />
          </Col>
          <Col>
            <ChanelCard
              api={ApiUniversitario}
              channelTitle="Pão Diário Universitário"
            />
          </Col>
        </Row>
        <h2 className="my-3">Yearly by Channel</h2>

        <Row>
          <Col>
            <ChanelGroupedCard
              api={ApiPaoDiario}
              apis={[
                {
                  api: ApiPaoDiario,
                  title: "Pão Diário",
                  color: "rgba(248, 179, 48, 0.5)",
                },
                {
                  api: ApiMilitar,
                  title: "Pão Diário Militar",
                  color: "rgb(53, 162, 235)",
                },
                {
                  api: ApiUniversitario,
                  title: "Pão diário Universitário",
                  color: "rgb(75, 192, 192)",
                },
              ]}
              channelTitle="Audience registers by year"
            />
          </Col>
        </Row>
      </Row>
    </>
  );
}
