import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import Container from "react-bootstrap/Container";

export default function Layout() {
  return (
    <>
      <Navbar />
      <Container>
        <Outlet />
      </Container>
    </>
  );
}
